<template>
  <b-form-row>

    <!-- #region::Name router link -->
    <b-col
      cols="12"
      md="auto"
      class="align-self-center"
    >
      <h5
        class="font-weight-bolder"
      >
        <router-link
          v-if="id"
          :to="onOpenProductDetails()"
          :target="openInNewTab ? '_blank' : ''"
          @mouseenter.native="handleHoverEvent"
        >{{ name }}
        </router-link>
        <span v-else>{{ name }}</span>
      </h5>
    </b-col>
    <!-- #endregion::Name router link -->

    <!-- #region::Product type label -->
    <b-col
      v-if="$ability.can('read', 'Product_type')"
      cols="12"
      md="auto"
    >
      <b-alert
        :variant="getTypeProductStyle"
        :class="getTypeProductStyle"
        class="green-style"
        show
      >
        <div class="alert-body">
          <span><strong>{{ type }}</strong></span>
        </div>
      </b-alert>
    </b-col>
    <!-- #endregion::Product type label -->

    <!-- #region::Count label -->
    <b-col
      v-if="$ability.can('read', 'Product_qty')"
      cols="12"
      md="auto"
    >
      <b-alert
        variant="principal-style"
        class="principal-style"
        show
      >
        <div class="alert-body">
          <span><strong>Cantidad: {{ count }}</strong></span>
        </div>
      </b-alert>
    </b-col>
    <!-- #endregion::Count label -->

    <!-- #region::Delivered label -->
    <b-col
      v-if="delivered === 'Si' || delivered === 'Entregado'"
      cols="12"
      md="auto"
    >
      <div class="banner-alert alert-blue mx-0">
        <div class="alert-body">
          <span v-if="delivered === 'Si'"><strong>Entrega</strong></span>
          <span v-if="delivered === 'Entregado'"><strong>Entregado</strong></span>
        </div>
      </div>
    </b-col>
    <!-- #endregion::Delivered label -->

  </b-form-row>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCol, BAlert, BFormRow,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BAlert,
    BFormRow,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    saveRoute: {
      type: Boolean,
      default: true,
    },
    setPiecesQuery: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: null,
    },

    // * for delivered tag
    showDeliveredTag: {
      type: Boolean,
      default: false,
    },
    delivered: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      localCountPiecesQuery: '',
      localProductPiecesQuery: 0,
    }
  },
  computed: {
    ...mapGetters({
      getCountPiecesQuery: 'prices/getCountPiecesQuery',
      getProductPiecesQuery: 'prices/getProductPiecesQuery',
    }),
    /**
     * Cantidad de piezas que se muestran en el detalle de producto
     * cuando todas las piezas esta agregadas a un circuito
     */
    countPiecesQuery: {
      get() { return this.getCountPiecesQuery },
      set(value) { this.setCountPiecesQuery(value) },
    },
    /**
     * Query necesario para cargar las piezas en el detalle de producto
     * cuando todas las piezas esta agregadas a un circuito
     */
    productPiecesQuery: {
      get() { return this.getProductPiecesQuery },
      set(value) { this.setProductPiecesQuery(value) },
    },
    /**
     * Determina el estilo del tag en base al tipo del producto
     */
    getTypeProductStyle() {
      switch (this.type) {
        case 'Nuevo':
          return 'green-style'
        case 'Usado':
          return 'orange-style'
        default:
          return 'gray-style'
      }
    },
    /**
     * Determina si el elmento se mostrará en una nueva pestaña
     */
    openInNewTab() {
      const routes = [
        'refund-details',
      ]
      return routes.includes(this.$route.name)
    },
  },
  created() {
    if (this.saveRoute) {
      if (this.$route.name === 'new-circuit') {
        this.setOriginProductRoute({
          name: this.$route.name,
          params: {
            number: this.$route.path.split('/')[4],
          },
        })
        return
      }

      this.setOriginProductRoute({ name: this.$route.name })
    }
  },
  methods: {
    ...mapActions({
      setCountPiecesQuery: 'pieces/setCountPiecesQuery',
      setProductPiecesQuery: 'pieces/setProductPiecesQuery',
      setOriginProductRoute: 'products/setOriginProductRoute',
    }),
    onOpenProductDetails() {
      let query = ''
      let route = null

      if (this.setPiecesQuery && this.product) {
        for (let index = 0; index < this.product.pieces.length; index += 1) {
          query += `pieces[${index}]=${this.product.pieces[index].IdPiece}&`
        }

        this.localCountPiecesQuery = this.product.pieces.length
        this.localProductPiecesQuery = query

        route = {
          name: 'product-details-pieces',
          params: {
            /**
             * Identificador del producto
             */
            id: this.id,
            /**
             * Arreglo de identificadores de piezas que se muestran
             * en el detalle del producto
             */
            query,
            /**
             * Indica la cantidad de piezas que se deben mostrar
             */
            count: this.product.pieces.length,
            /**
             * Valida el estado del producto que puede ser mostrado o no
             * en el detalle del producto
             */
            visible: this.product.status_info_product === 0 ? 1 : 0,
          },
        }
      } else {
        route = { name: 'product-details', params: { id: this.id } }
      }

      return route
    },
    handleHoverEvent() {
      this.countPiecesQuery = this.localCountPiecesQuery
      this.productPiecesQuery = this.localProductPiecesQuery
    },
  },
}
</script>
