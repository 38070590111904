<template>
  <b-container
    fluid
    class="px-0 ml-1"
  >
    <b-row
      align-h="between"
    >

      <!-- #region begin::Product details title -->
      <b-col cols="6">
        <RefundProductTitleDetails
          :id="product.IdProduct"
          :name="product.Name"
          :type="product.Type"
          :count="product.QtyIventory"
          :save-route="false"
          :delivered="product.Delivered"
          :set-pieces-query="setPiecesQuery"
          :product="setPiecesQuery ? product : null"
        />
      </b-col>
      <!-- #endregion end::Product details title -->

      <b-col cols="6">
        <b-container fluid>
          <b-row
            align-h="end"
            align-v="center"
          >

            <!-- #region begin::Delete product button -->
            <b-button
              v-if="showDeleteButton"
              v-b-tooltip.hover.top="'Eliminar'"
              variant="flat-secondary"
              class="btn-icon px-2"
              @click="onDeleteProduct"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
            <!-- #endregion end::Delete product button -->

            <!-- #region begin::Edit product button -->
            <b-button
              v-if="showEditButton"
              v-b-tooltip.hover.top="'Editar'"
              variant="flat-secondary"
              class="btn-icon px-2"
              @click="$refs.updateProductModal.showModal()"
            >
              <feather-icon icon="Edit3Icon" />
            </b-button>
            <!-- #endregion end::Edit product button -->

          </b-row>
        </b-container>
      </b-col>
    </b-row>

    <RefundProductDetails
      :product="product"
      :disabled-form-components="disabledFormComponents"
      :icon-toggle="iconToggle"
      :visible="visible"
      @toggle-collapse="collapse"
    />

  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BContainer, BButton, VBTooltip,
} from 'bootstrap-vue'

// import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RefundProductDetails from '@/modules/store/refunds/components/cards/RefundProductDetails.vue'
import RefundProductTitleDetails from '@/modules/store/refunds/components/cards/RefundProductTitleDetails.vue'

import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BContainer,
    RefundProductDetails,
    RefundProductTitleDetails,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    collapseItemId: {
      type: String,
      required: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },

    // * 22/11/2022 - TAG: For quote details
    disabledFormComponents: {
      type: Boolean,
      default: false,
    },
    setPiecesQuery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      iconToggle: 'ChevronDownIcon',
      selectedDeliveryMethod: null,
    }
  },
  computed: {
    ...mapGetters({
      getDeliveryMethods: 'quotes/getDeliveryMethods',
    }),
    deliveryMethodUUID() {
      return uuidv4()
    },
  },
  watch: {
    selectedDeliveryMethod(newDeliveryMethod) {
      this.$set(this.product, 'DeliveredMethod', newDeliveryMethod.text)
    },
  },
  created() {
    this.visible = this.isVisible
    this.setToggleIcon()

    if (this.product.DeliveredMethod) {
      this.selectedDeliveryMethod = { text: this.product.DeliveredMethod }
    }
  },
  methods: {
    ...mapActions({
      addDetailProduct: 'orders/addDetailProduct',
    }),
    collapse() {
      if (!this.visible) {
        this.$emit('rendering', true)
      }
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
    onDeleteProduct() {
      const itHasDeliveredPieces = piece => piece.Delivered === 'Entregado'
      const hasDeliveredPieces = this.product.pieces.some(itHasDeliveredPieces)

      if (hasDeliveredPieces) {
        this.showToast(
          'Error de validación',
          'El producto no puede ser eliminado ya que tiene piezas entregadas.',
          'warning',
        )

        return
      }

      const orderProduct = { ...this.product }
      orderProduct.pieces = []
      this.addDetailProduct(orderProduct)

      this.showToast(
        'Producto eliminado',
        'El producto se ha eliminado correctamente de la orden',
        'success',
      )
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
