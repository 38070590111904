/**
* Nivel de habilitación
* @summary Devuelve el nivel de habilitación de un de un producto usado
* @param {string} value - Precio del producto seleccionado
* @return {string} - Nivel de habilitación
*/
function usedProductRatingLevel(value) {
  switch (value) {
    case 'PriceLf':
    case 'OfferPrice':
      return 'L/F'

    case 'PriceDr':
      return 'D/R'

    default:
      return 'R'
  }
}

export default usedProductRatingLevel
